<template>
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
      <a-card size="small">
        <template slot="title">
          <strong>{{ titleFormat(whatref) }}</strong>
          <!-- <a-input
            v-model="searchText"
            class="float-right ml-2"
            placeholder="Find data..."
            style="width: 200px;"
          /> -->
        </template>
        <div class="row">
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <!-- <ag-grid-vue
              v-if="columnDefs.length >= 6"
              style="height: 50vh;"
              :class="
                settings.theme === 'dark'
                  ? 'ag-theme-balham-dark mt-2'
                  : 'ag-theme-balham mt-2'
              "
              :gridOptions="gridOptions"
              :columnDefs="columnDefs"
              :pagination="true"
              :paginationPageSize="10"
              :cacheQuickFilter="true"
              :quickFilterText="searchText"
              :accentedSort="false"
              :sortingOrder="['asc', 'desc']"
              :rowData="rowData"
              @grid-ready="onGridReady"
              @first-data-rendered="onFirstDataRendered"
            >
            </ag-grid-vue> -->
              <!-- v-else -->
            <a-table
              :bordered="false"
              :columns="columns"
              :data-source="datatable"
              size="small"
              :pagination="{
                defaultPageSize: 10,
                showQuickJumper: true,
                hideOnSinglePage: true,
                showSizeChanger: true,
                showTotal: (total) => `Total ${total} items`,
                pageSizeOptions: ['10', '20', '30'],
              }"
            >
              <div
                slot="filterDropdown"
                slot-scope="{
                  setSelectedKeys,
                  selectedKeys,
                  confirm,
                  clearFilters,
                  column,
                }"
                style="padding: 8px;"
              >
                <a-input
                  v-ant-ref="(c) => (searchInput = c)"
                  :placeholder="`Search ${column.dataIndex}`"
                  :value="selectedKeys[0]"
                  style="width: 188px; margin-bottom: 8px; display: block;"
                  @change="
                    (e) => setSelectedKeys(e.target.value ? [e.target.value] : [])
                  "
                  @pressEnter="
                    () => handleSearch(selectedKeys, confirm, column.dataIndex)
                  "
                />
                <a-button
                  type="primary"
                  icon="search"
                  size="small"
                  style="width: 90px; margin-right: 8px;"
                  @click="
                    () => handleSearch(selectedKeys, confirm, column.dataIndex)
                  "
                >
                  Search
                </a-button>
                <a-button
                  size="small"
                  style="width: 90px;"
                  @click="() => handleReset(clearFilters)"
                >
                  Reset
                </a-button>
              </div>
              <a-icon
                slot="filterIcon"
                slot-scope="filtered"
                type="search"
                :style="{ color: filtered ? '#108ee9' : undefined }"
              />
              <template
                slot="customRender"
                slot-scope="text, record, index, column"
              >
                <span v-if="searchText && searchedColumn === column.dataIndex">
                  <template
                    v-for="(fragment, i) in text
                      .toString()
                      .split(
                        new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i')
                      )"
                  >
                    <mark
                      v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                      :key="i"
                      class="highlight"
                      >{{ fragment }}</mark
                    >
                    <template v-else>{{ fragment }}</template>
                  </template>
                </span>
                <template v-else>
                  {{ text }}
                </template>
              </template>
              <span slot="autoGenerateNomor" slot-scope="text, record, index">
                {{ index + 1 }}
              </span>
            </a-table>
          </div>
        </div>
      </a-card>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import * as lou from '@/services/data/lou'
import moment from 'moment'

export default {
  watch: {
    $route(to, from) {
      this.rowData = null
      this.datatable = null
      this.columnDefs = []
      this.columns = []
      this.datenow = lou.datenow()
      // this.gridOptions = {}
      // this.gridApi = this.gridOptions.api
      // this.gridColumnApi = this.gridOptions.columnApi
      // console.log('this.user.permissions', this.user.permissions)
      var whatref = this.$route.meta.key.replace('/', '')
      // console.log('whatref', whatref)
      this.whatref = whatref
      this.getAllData()
    },
  },
  computed: {
    ...mapState(['settings', 'user']),
  },
  created () {
    this.datenow = lou.datenow()
    // this.gridOptions = {}
    // this.gridApi = this.gridOptions.api
    // this.gridColumnApi = this.gridOptions.columnApi
    // console.log('this.user.permissions', this.user.permissions)
    var whatref = this.$route.meta.key.replace('/', '')
    // console.log('whatref', whatref)
    this.whatref = whatref
    this.getAllData()
  },
  data() {
    return {
      whatref: '',
      searchText: '',
      columnDefs: [],
      rowData: [],
      gridOptions: null,
      columns: [
        // {
        //   title: 'Anggota Komite',
        //   dataIndex: 'anggotakomite',
        //   scopedSlots: {
        //     filterDropdown: 'filterDropdown',
        //     filterIcon: 'filterIcon',
        //     customRender: 'customRender',
        //   },
        //   onFilter: (value, record) =>
        //     moment(record.anggotakomite, 'YYYY-MM-DD')
        //       .format('DD-MM-YYYY')
        //       .toString()
        //       .toLowerCase()
        //       .includes(value.toLowerCase()),
        //   onFilterDropdownVisibleChange: (visible) => {
        //     if (visible) {
        //       setTimeout(() => {
        //         this.searchInput.focus()
        //       }, 0)
        //     }
        //   },
        //   sorter: (a, b) => a.length - b.length,
        // },
      ],
      datatable: [],
    }
  },
  methods: {
    moment,
    getColumnDefs() {
      var key = Object.keys(this.rowData[0])
      // console.log('key', key)
      var exception = ['id', 'index', 'tab']
      this.columns = [
        {
          title: '#',
          scopedSlots: {
            customRender: 'autoGenerateNomor',
          },
        },
      ]
      if (key.length !== 0) {
        for (let i = 0; i < key.length; i++) {
          const element = key[i]
          if (!exception.includes(element)) {
            this.columnDefs.push({
              headerName: lou.capitalizeTheFirstLetterOfEachWord(element),
              field: element.toLowerCase(),
              resizeable: true,
            })
            this.columns.push({
              title: lou.capitalizeTheFirstLetterOfEachWord(element),
              dataIndex: element,
              scopedSlots: {
                filterDropdown: 'filterDropdown',
                filterIcon: 'filterIcon',
                customRender: 'customRender',
              },
              onFilter: (value, record) =>
                record[element]
                  .toString()
                  .toLowerCase()
                  .includes(value.toLowerCase()),
              onFilterDropdownVisibleChange: (visible) => {
                if (visible) {
                  setTimeout(() => {
                    this.searchInput.focus()
                  }, 0)
                }
              },
              // sorter: (a, b) => a.length - b.length,
            })
          }
        }
      }
    },
    async getAllData() {
      var res = await lou.readMaster(this.whatref)
      this.rowData = res.data
      this.datatable = res.data
      if (res.data.length !== 0) this.getColumnDefs()
    },
    onGridReady(params) {
      // params.api.sizeColumnsToFit()
      this.gridApi = params.api
      this.gridColumnApi = params.columnApi
    },
    autoSizeAll(skipHeader, { autosizecolumn = true }) {
      if (autosizecolumn) {
        var allColumnIds = []
        // console.log('this.gridColumnApi', this.gridColumnApi)
        this.gridColumnApi.getAllColumns().forEach(function (column) {
          allColumnIds.push(column.colId)
        })
        this.gridColumnApi.autoSizeColumns(allColumnIds, skipHeader)
      } else {
        this.gridApi.sizeColumnsToFit()
      }
    },
    onFirstDataRendered() {
      this.autoSizeAll(false, { autosizecolumn: true })
    },
    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm()
      this.searchText = selectedKeys[0]
      this.searchedColumn = dataIndex
    },

    handleReset(clearFilters) {
      clearFilters()
      this.searchText = ''
    },
    titleFormat(word) {
      return lou.capitalizeTheFirstLetterOfEachWord(word)
    },
  },
}
</script>

<style></style>
